<template>
  <div v-if="ready">
    <MenuUser />
    <div class="dash-zone-bloc-1 wf-section">
      <div class="dash-top-zone w-container">
        <div class="w-layout-grid grid-dashboard">
          <div id="w-node-_2ae1f182-b536-39b3-6baf-3ffca388242f-f1b92056" class="bloc-mes-etapes">
            <div class="div-top-notif">
              <div class="notif-titre"><p class="paragraph notifications">vos notifications</p></div>
              <div class="etapes notif-en-cours" v-for="(e,i) in eventsN" :key="i">
                <p class="paragraph classique-light gris-fonc">{{e.titre}}</p>
                <a :href="e.url" class="button-2 w-button">cliquez ici pour finaliser</a>
              </div>
            </div>
            <div class="all-step">
              <div class="etapes ligne-notifications" v-for="(e,i) in eventsF" :key="i">
                <p class="paragraph classique-light gris-clair"><img :src="require('@/assets/images/ic_check.svg')" loading="lazy" alt="" class="ic_notif_terminee"> {{e.titre}}</p>
                <p class="paragraph classique-light gris-clair date">{{$store.api.timestampToDate(e.date_create, false)}}</p>
              </div>
            </div>
          </div>
          <div class="bloc-mes-informations">
            <div class="titre-bloc-info"><p class="paragraph informations">mes informations</p></div>
            <div class="zone-nom">
              <div class="cercle-id"><p class="id-nom">{{initial}}</p></div>
              <p class="paragraph-6">
                <span class="text-span-4">{{user.prenom}} {{user.nom}}</span><br />
                {{user.email}}
              </p>
            </div>
            <div class="ligne-separation"></div>
            <div class="zone-coordonnees">
              <p class="paragraph-7">
                <span class="text-span-5">Adresse :</span><br />
                {{souscription.logement.adresse}}<br>
                {{souscription.logement.cp}} {{souscription.logement.ville}}
              </p>
            </div>
            <div class="zone-statut">
              <p class="paragraph-8"><span class="text-span-5">Statut : </span></p>
              <div class="div-block-8">
                <p class="tag-statut">adhérent Pautions</p>
                <img :src="require('@/assets/images/badge_pautions_statut.svg')" loading="lazy" width="35" alt="" class="badge-statut" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dash-zone-bloc-2 wf-section">
      <div class="dash-second-zone w-container">
        <div class="w-layout-grid grid-dashboard-suite-v2">
          <div id="w-node-a11b54ea-8bc6-7797-e31c-79f11ce683d2-f1b92056" class="bloc-contact-support-v2">
            <div class="div-block-15">
              <p class="paragraph-9-v2">Vous avez des questions ou besoin d&#x27;assistance ?</p>
              <p class="paragraph-10-v2"><span class="text-span-6">Contactez</span> notre support client.</p>
            </div>
            <a @click="$parent.modal()" class="btn-cliquez-ici-v2 rose-v2 w-button">cliquez-ici</a>
          </div>
          <div id="w-node-a11b54ea-8bc6-7797-e31c-79f11ce683dc-f1b92056" class="bloc-documents-v2">
            <div class="titre-bloc-info"><p class="paragraph documents">vos contrats</p></div>
            <a :href="souscription.docs.contrat != null ? souscription.docs.contrat : 'javascript:void(0)'" :target="souscription.docs.contrat != null ? '_blakn' : '_self'" style="text-decoration: auto;">
              <div class="zone-nom-contat">
                <div class="cercle-id contrat"><img :src="require('@/assets/images/ic_pdf_blanc.svg')" loading="lazy" width="20" alt="" /></div>
                <div class="div-block-18">
                  <p class="txt-nom-contrat">
                    <span class="text-span-4">Assurance Zéro Dépôt</span><br />
                    Date de prise d'effet : {{formatDate(souscription.logement.date_bail)}}<br />
                  </p>
                  <p v-if="souscription.docs.contrat === null" class="txt-info-contrat-non-signe">Votre contrat est en attente de signature par votre propriétaire.<br /></p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="dash-zone-bloc-3 wf-section">
      <div class="dash-second-zone w-container">
        <div class="w-layout-grid grid-dashboard-sinitre-zone">
          <div id="w-node-_52dd7a17-d85b-9937-0e1d-354f9757475a-f1b92056" class="bloc-sinitre-colonne">
            <div class="titre-sinistre-bloc">
              <p class="titre-sinistre-bloc"><span class="text-span-23">Un sinistre ?</span> Déclarez-le.</p>
              <p class="detail-sinistre-bloc">Nous vous donnons la procédure à suivre.</p>
              <a href="/#/sinistre" class="btn-cliquez-ici-sinistre rose-sinistre dash-sinistre w-button">cliquez ici</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MenuUser from '@/components/Menu/Utilisateur';
export default {
  name: "Home",
  data(){
    return {
      ready:false,
      user: {
        nom: null,
        prenom: null,
        email: null
      },
      events:[],
      souscription:{
        "id": null,
        "provisoir": null,
        "loyer": null,
        "meublee": null,
        "frais_adhesion": null,
        "logement": {
          "adresse": null,
          "adresse2": null,
          "cp": null,
          "ville": null,
          "pays": null,
          "date_bail": null
        },
        "proprietaire_id": null,
        "coup_dur": null,
        "paiement": {
          "periode": null,
          "type_id": null,
          "stripe_cb_id": null,
          "stripe_id": null
        },
        "uuid": null,
        "last_step": null,
        "prix_mensuel": null,
        "prix_annuel": null,
        "status_id": null,
        "status": null,
        "date_create": null,
        "date_update": null,
        "user_id": null
      }
    }
  },
  components: {
    MenuUser
  },
  computed:{
    eventsN(){
      return this.events.filter(e => e.finish === false)
    },
    eventsF(){
      return this.events.filter(e => e.finish === true)
    },
    dateSouscription(){
      var tmp = this.souscription.logement.date_bail;
      if(tmp === null){
        return null
      }
      return tmp.split('-').reverse().join('/')
    },
    initial(){
      var tmp = ""
      tmp += this.user.prenom.toUpperCase()[0]
      tmp += this.user.nom.toUpperCase()[0];
      return tmp;
    }
  },
  methods: {
    formatDate(date) {
      console.log(date);
      if(date === null){
        return 'Erreur';
      }
      var tmp  = date.split('-');
      if(tmp.length != 3){
        return 'Erreur';
      }
      return tmp.reverse().join('/');
    }
  },
  beforeMount() {
    this.$store.api.ajax('/home/init', null, res => {
      if(res.status === true){
        this.user = res.data.user
        this.events = res.data.events
        this.souscription = res.data.souscription
        this.ready = true;
      }
    })
  },
  beforeCreate() {
    document.getElementsByTagName('body')[0].className = "body-crm";
  },
};
</script>
