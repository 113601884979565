<template>
  <div v-if="ready">
    <Menu/>
    <div class="zone-bloc-1 wf-section">
      <div class="container-2 w-container">
        <p class="paragraph titre-bloc">Vous êtes en possession de votre bail et avez l'accord de votre propriétaire / bailleur ?</p>
        <p class="paragraph titre-bloc _2"><span class="text-span">Finalisez en ligne</span> votre souscription à l’assurance Zéro Dépôt.</p>
        <a :href="'/#/zero-depot?uuid='+souscription.uuid+'&final=true'" class="button violet w-button">cliquez ici</a>
      </div>
    </div>
    <div class="zone-bloc-2 wf-section">
      <div class="container-3 w-container">
        <div class="w-layout-grid grid-2">
          <div id="w-node-_69a5f4e7-a6ac-545d-2301-eaa753fa2349-86784b26" class="bloc-mes-etapes">
            <div class="div-top-notif">
              <div class="notif-titre"><p class="paragraph etape">mes étapes</p></div>
              <div class="etapes notif-en-cours" v-for="(e,i) in eventsN" :key="i">
                <p class="paragraph classique-light gris-fonc">{{e.titre}}</p>
                <a :href="e.url" class="button-2 w-button">cliquez ici pour finaliser</a>
              </div>
            </div>
            <div class="all-step">
              <div class="etapes ligne-notifications" v-for="(e,i) in eventsF" :key="i">
                <p class="paragraph classique-light gris-clair">{{e.titre}}</p>
                <p class="paragraph classique-light gris-clair date">{{$store.api.timestampToDate(e.date_create, false)}}</p>
              </div>
            </div>
          </div>
          <div class="attestation">
            <p class="titre-rose"><span class="rose">Votre attestation provisoire</span></p>
            <p class="txt-bloc-ap">Récupérez-la gratuitement et ajoutez-la à votre dossier de location.</p>
            <div class="div-block-19">
              <a :href="souscription.docs.attestation" target="_blank" class="btn-cliquez-ici rose w-button">Télécharger</a>
              <a href="javascript:void(0)" @click="regenerate()" class="btn-cliquez-ici rose regenerer w-button">Regénérer</a>
            </div>
          </div>
          <!-- <div class="attestation" v-if="souscription.docs.attestation != null">
            <p class="titre-rose"><span class="rose">Votre attestation provisoire</span></p>
            <p class="paragraph-3">Récupérez-la gratuitement et ajoutez-la à votre dossier de location.</p>
            <a :href="souscription.docs.attestation" target="_blank" class="btn-cliquez-ici rose w-button">cliquez ici</a>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from '@/components/Menu/NonUtilisateur';
export default {
  name: "Home",
  components: {
    Menu
  },
  data(){
    return {
      ready:false,
      generate:false,
      events: [],
      souscription:{}
    }
  },
  computed:{
    eventsN(){
      return this.events.filter(e => e.finish === false)
    },
    eventsF(){
      return this.events.filter(e => e.finish === true)
    },
  },
  beforeMount() {
    this.$store.api.ajax('/home/init', null, res => {
      if(res.status === true){
        this.events = res.data.events;
        this.souscription = res.data.souscription;
        this.ready = true;
      }
    })
  },
  beforeCreate() {
    document.getElementsByTagName('body')[0].className = "body-crm";
  },
  methods:{
    regenerate(){
      if(this.generate === true){
        return false;
      }
      this.generate = true;
      this.$store.api.ajax('/zero-depot/attestation/'+this.souscription.uuid, null, res => {
        if(res.status === true){
          window.open(this.souscription.docs.attestation, '_blank');
        }
        this.generate = false;
      })
    }
  }
};
</script>
