<template>
  <div>
    <HomeSouscrit v-if="$store.api.user.souscrit === true && $store.api.user.role === 3" />
    <HomeNonSouscrit v-if="$store.api.user.souscrit === false && $store.api.user.role === 3 && $store.api.user.version === false" />
    <V2HomeNonSouscrit v-if="$store.api.user.souscrit === false && $store.api.user.role === 3 && $store.api.user.version === 2" />
    <HomeProprietaire v-if="$store.api.user.role === 4" />
    <section class="d-none popup-support" ref="modalSC">
      <div class="popup-content popup-main">
        <div class="div-zone-cross">
          <div data-w-id="33942d34-3e29-a873-2cd3-05b4ac82f206" class="div-zone-image-cross"><img @click="modal()" :src="require('@/assets/images/ic_cross_close.svg')" loading="lazy" width="20" alt="" class="image-cross-close"></div>
        </div>
        <div class="div-popup-content">
          <p class="paragraph-19">Support client <span class="text-span-27">Pautions</span></p>
          <div class="ligne-separation-popup"></div>
          <p class="paragraph-21">Vous avez des questions ou besoin d&#x27;assistance ?N&#x27;hésitez pas à contacter notre équipe qui répondra à toutes vos questions, du lundi au vendredi, de 9h à 18h.</p>
          <p class="paragraph-22">Par téléphone au <a target="_blank" href="tel://+33970386826" class="link-2">09 70 38 68 26</a>
          </p>
          <p class="paragraph-23">Par mail à <a href="mailto:support.client@pautions.fr" class="link-3">support.client@pautions.fr</a>
          </p>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import HomeNonSouscrit from '@/views/home/HomeNonSouscrit';
import V2HomeNonSouscrit from '@/views/home/v2/HomeNonSouscrit';
import HomeSouscrit from '@/views/home/HomeSouscrit';
import HomeProprietaire from '@/views/home/HomeProprietaire';
export default {
  name: "",
  components: {
    HomeNonSouscrit, HomeSouscrit, HomeProprietaire, V2HomeNonSouscrit
  },
  beforeMount() {
    if([1,2].includes(this.$store.api.user.role)){
      this.$router.push({path:"/admin/inscrit"});
    }
    if([6].includes(this.$store.api.user.role)){
      this.$router.push({path:"/partenaire/liste"});
    }
    if([5].includes(this.$store.api.user.role)){
      this.$router.push({path:"/apporteur/liste"});
    }
  },
  methods: {
    modal() {
      var elt = Object.values(this.$refs.modalSC.classList);
      var check = elt.find(c => c === 'd-none');
      if(check === undefined){
        this.$refs.modalSC.classList.add('d-none')
      }else{
        this.$refs.modalSC.classList.remove('d-none')
      }
      this.$refs.modalSC.style = null;
    }
  },
  beforeCreate() {
    document.getElementsByTagName('body')[0].className = "body-crm";
  },
}
</script>
<style>
.popup-support{
  min-height: 150%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  display: block;
}
.popup-support.d-none {
  display: none !important;
}
.popup-main{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 479px){
  .popup-support {
    display: block;
  }
  .paragraph-22 {
    padding-right: 0px;
  }
}
</style>
